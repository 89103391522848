import React from 'react';
import "./Comm.css";
import Button from '../Props/Button';

const Comm = () => {
  return (
    <div className='comm'>
        <div className='comm-mid'>
            <h1>join our community</h1>
            <p>This HTML file is a template.
      If you open it directly in the browser
      This HTML file is a template.
      If you open it directly in the browser
      This HTML file is a template.
      If you open it directly in the browser
      This HTML file is a template.
      If you open it directly in the browser</p>
            <Button
            class="btn-big"
            name="join discord"
            />
        </div>
    </div>
  )
}

export default Comm