import React from 'react';
import "./What.css";

const What = () => {
  return (
    <div className='what'>
        <h1>what is imposter</h1>
        <div></div>
        <p>This HTML file is a template.
      If you open it directly in the browser, you will see an empty page.</p>
      <p>
      This HTML file is a template.
      If you open it directly in the browser, you will see an empty page.
      This HTML file is a template.
      If you open it directly in the browser, you will see an empty page.
      </p>
      <p>
      This HTML file is a template.
      If you open it directly in the browser, you will see an empty page.
      This HTML file is a template.
      If you open it directly in the browser, you will see an empty page.
      </p>
    </div>
  )
}

export default What